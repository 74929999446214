import { Badge, BadgeGroup, Button, Col, Container, ImageGallery, Link, PageBanner, Row, Text, Title } from '@zigurous/react-components'; // prettier-ignore
import { graphql } from 'gatsby';
import React from 'react';
import { Page } from '../../components';
import { trackEvent } from '../../firebase/analytics';
import type { GameData } from '../../types';

interface GameProps {
  data: { game: GameData };
  location: Location;
}

export default function Game({ data, location }: GameProps) {
  const { game } = data;
  const metadata = {
    url: `https://zigurous.com/games/${game.id}`,
    title: `Zigurous • ${game.title}`,
    description: game.description,
    image: game.image.sharp.original.src,
  };
  return (
    <Page className="game" location={location} metadata={metadata}>
      <PageBanner className="bg-surface-1">
        <Container fluid>
          <Row className="align-items-center">
            <Col xl={4}>
              <Title subtitle size={5}>
                {game.subtitle}
              </Title>
              <Title as="h1">{game.title}</Title>
              {game.badges && game.badges.length > 0 && (
                <BadgeGroup className="margin-bottom-lg">
                  {game.badges.map(badge => (
                    <Badge key={badge} pill>
                      {badge}
                    </Badge>
                  ))}
                </BadgeGroup>
              )}
            </Col>
            <Col xl={8}>
              <Text size="lg">{game.description}</Text>
              {game.callToAction && game.callToActionURL && (
                <div className="margin-top-xl margin-bottom-xl">
                  <Link to={game.callToActionURL} external unstyled>
                    <Button
                      icon="open_in_new"
                      iconAlignment="right"
                      onClick={() => {
                        trackEvent('cta_button', {
                          content_type: 'button',
                          event_category: 'engagement',
                          event_label: game.callToAction,
                          item_id: game.id,
                        });
                      }}
                      shape="rounded"
                      style="outline"
                      tabIndex={-1}
                    >
                      {game.callToAction}
                    </Button>
                  </Link>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </PageBanner>
      <Container className="margin-top-xxxl" fluid>
        {game.screenshots && (
          <React.Fragment>
            <Title as="h2" size={4}>
              Screenshots
            </Title>
            <ImageGallery
              animated
              columns={4}
              images={game.screenshots.map(image => ({
                width: image.sharp.original.width,
                height: image.sharp.original.height,
                src: image.sharp.original.src,
              }))}
            />
          </React.Fragment>
        )}
      </Container>
    </Page>
  );
}

export const query = graphql`
  query ($id: String!) {
    game: gamesJson(id: { eq: $id }) {
      badges
      callToAction
      callToActionURL
      description
      id: jsonId
      image {
        sharp: childImageSharp {
          original {
            src
            width
            height
          }
        }
      }
      screenshots {
        sharp: childImageSharp {
          original {
            src
            width
            height
          }
        }
      }
      subtitle
      title
    }
  }
`;
